<script lang="ts">
    import { Skeleton } from '$lib/components/ui/skeleton';

    export let data;
    export let newChat;
</script>

<div class="flex max-h-[82vh] flex-1 flex-col gap-[40px] px-4 py-3">
    {#if data.lastContactID != false}
        <div class="flex w-[100%] flex-col space-y-2">
            <Skeleton class="h-4 w-[60%]" />
            <Skeleton class="h-4 w-[50%]" />
        </div>
        <div class="flex w-[100%] flex-col items-end justify-end space-y-2">
            <Skeleton class="h-4 w-[50%]" />
            <Skeleton class="h-4 w-[60%]" />
            <Skeleton class="h-4 w-[50%]" />
        </div>
        <div class="flex w-[100%] flex-col space-y-2">
            <Skeleton class="h-4 w-[50%]" />
            <Skeleton class="h-4 w-[60%]" />
        </div>
    {:else}
        <div class="flex h-full max-h-[82vh] flex-col items-center justify-center">
            {#if !newChat}
                <div class="flex h-full flex-1 items-center justify-center">
                    <span>👋 You got no messages yet.</span>
                    <span class="hidden md:block">&nbsp;Say 'Hi!' to someone!</span>
                </div>
            {/if}
        </div>
    {/if}
</div>
